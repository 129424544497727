import { Component, Inject, OnInit, Optional, Self } from '@angular/core';
import { FormControl, FormGroup, NgControl, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MAT_FORM_FIELD, MatFormField } from '@angular/material/form-field';
import { SvKey, SvKeyType } from 'src/app/admin/interfaces/licence.interface';
import { MacAddr } from 'src/app/admin/interfaces/mac-addr.class';
import { LicencesService } from 'src/app/admin/services/licences.service';
import { KeyTypes } from 'src/app/utils/constants';
import { UiService } from 'src/app/utils/ui.service';

@Component({
  selector: 'add-key-dialog',
  templateUrl: './add-key-dialog.component.html',
  styleUrls: ['./add-key-dialog.component.less']
})
export class AddKeyDialogComponent implements OnInit{
  svKey!: SvKey;
  svKeyType: KeyTypes[] = Object.values(KeyTypes);
  numberFormControl: FormControl = new FormControl('', [Validators.required]);
  nameFormControl: FormControl = new FormControl('', [Validators.required]);
  form: FormGroup = new FormGroup({
    macAddr: new FormControl(new MacAddr('', '', '', '', '', ''))
  });

  constructor(public dialogRef: MatDialogRef<AddKeyDialogComponent>, @Inject(MAT_DIALOG_DATA) public userCustomer: number,
              public licencesService: LicencesService, public uiService: UiService,
              @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
              @Optional() @Self() public ngControl: NgControl) {}

  ngOnInit(): void {
    this.svKey  = {
      type: KeyTypes.Imei,
      id: undefined,
      number: '',
      simNickName: '',
      msisdn: '',
      customerId: this.userCustomer
    };
  }

  typeChange = (event: {value: KeyTypes}): void => {
    const validators: ValidatorFn[] = [Validators.required];
    if(event.value === KeyTypes.Imei){
      validators.push(
        Validators.maxLength(15),
        Validators.minLength(15),
        Validators.pattern('^[0-9]*$')
      );
    }
    this.numberFormControl.setValidators(validators);
    this.numberFormControl.updateValueAndValidity();
  };

  isValid = (): boolean => {
    if(!!this.svKey.type){
      if(this.svKey.type !== KeyTypes.Macaddr){
        return this.numberFormControl.valid;
      }else{
        return this.form.controls[KeyTypes.Macaddr].status === 'VALID';
      }
    }else{
      return false;
    }
  };

  macAddrChange = (): void => {
    const val: MacAddr = this.form.controls[KeyTypes.Macaddr].value;
    this.svKey.number = `${val.mac1}:${val.mac2}:${val.mac3}:${val.mac4}:${val.mac5}:${val.mac6}`;
  };

  compareType = (svkeytype: SvKeyType, type: string): boolean => type === svkeytype.type;
}
