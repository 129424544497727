<h1 mat-dialog-title>Add key</h1>
<div mat-dialog-content>
  <div class="column">
    <mat-form-field *ngIf="svKeyType" appearance="fill">
      <mat-label>Key</mat-label>
      <mat-select (selectionChange)="typeChange($event)" [(ngModel)]="svKey.type" [compareWith]="compareType">
        <mat-option *ngFor="let svk of svKeyType" [value]="svk">
          {{svk}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="svKey.type!=='macaddr'" class="example-full-width" appearance="fill">
      <mat-label>Number</mat-label>
      <input matInput [formControl]="numberFormControl" [(ngModel)]="svKey.number">
      <mat-error *ngIf="numberFormControl.invalid">Invalid input for {{ svKey.type }}</mat-error>
    </mat-form-field>
    <div [formGroup]="form" *ngIf="svKey.type==='macaddr'">
      <mat-form-field class="mdc-form-field">
        <mat-label>Mac address</mat-label>
        <app-macaddr-input required (change)="macAddrChange()" formControlName="macAddr"></app-macaddr-input>
      </mat-form-field>
    </div>
    <mat-form-field class="example-full-width" appearance="fill">
      <mat-label>Nickname</mat-label>
      <input matInput [formControl]="nameFormControl" [(ngModel)]="svKey.simNickName">
    </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="null">Cancel</button>
  <button mat-button [disabled]="!isValid()" 
  [mat-dialog-close]="svKey" cdkFocusInitial>Ok</button>
</div>
