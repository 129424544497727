import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { LicencesService } from '../../../services/licences.service';
import { ConnectionHistory } from 'src/app/admin/interfaces/licence.interface';
import { MatPaginator } from '@angular/material/paginator';
import { Observable, map } from 'rxjs';
import { AggregatedData } from 'src/app/admin/interfaces/connection-history.interface';
import { UiService } from 'src/app/utils/ui.service';
import * as Highcharts from 'highcharts';
import * as constants from 'src/app/utils/constants';

@Component({
  selector: 'app-connection-history',
  templateUrl: './connection-history.component.html',
  styleUrls: ['./connection-history.component.less']
})
export class ConnectionHistoryComponent implements OnInit, OnChanges {
  @Input() customerIdInput!: number;
  @Input() startDate!: Date;
  @Input() endDate!: Date;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  displayedColumns: string[] = [
    'id',
    'date',
    'viserVersion',
    'licenceId',
    'IMSI',
    'IMEI',
    'UDID',
    'UNIQUE',
    'error',
    'request'
  ];

  connectionHistoryList: MatTableDataSource<ConnectionHistory> = new MatTableDataSource<ConnectionHistory>([]);
  isLoading: boolean = true;
  customerId!: number;
  nbConnectionOk: number = 0;
  nbConnectionKo: number = 0;
  chartOptions: any;
  chart: Highcharts.Chart | undefined;


  constructor(
    public licencesServices: LicencesService,
    private uiService: UiService
  ) { }


  ngOnInit(): void {
    this.customerId = this.customerIdInput;
    this.getConnectionHistory();
    this.getConnectionHistoryGraph();
  }

  initializeChart(dataChart: any[], typeAggregate: string): void {
    Highcharts.setOptions({
      lang: {
        rangeSelectorFrom: 'From',
        rangeSelectorTo: 'To',
        rangeSelectorZoom: ''
      }
    });

    this.chart = Highcharts.stockChart('container', {
      rangeSelector: {
        buttonTheme: {
          'fill': '#039',
          'stroke': 'none',
          'stroke-width': 0,
          'r': 8,
          'style': {
            color: 'white',
            fontWeight: 'bold'
          },
          'states': {
            hover: {},
            select: {
              fill: 'white',
              style: {
                color: '#039'
              }
            }
          },
          'width': 60
        },
        inputBoxBorderColor: 'gray',
        inputBoxWidth: 120,
        inputBoxHeight: 18,
        inputStyle: {
          color: '#039',
          fontWeight: 'bold',
          pointerEvents: 'none'

        },
        labelStyle: {
          color: 'silver',
          fontWeight: 'bold'
        },
        buttons: [
          {
            text: constants.last14D,
            events: {
              click: () => this.customButtonClick(false, constants.days, constants.last14Days)
            }
          },
          {
            text: constants.last12W,
            events: {
              click: () => this.customButtonClick(false, constants.weeks, constants.last12Weeks)
            }
          },
          {
            text: constants.lastY,
            events: {
              click: () => this.customButtonClick(true, '1', constants.lastYear)
            }

          },
          {
            text: constants.last3Y,
            events: {
              click: () => this.customButtonClick(true, '3', constants.last3Years)
            }
          },
          {
            text: constants.all,
            events: {
              click: () => this.customButtonClick(true, (constants.all).toLowerCase(), constants.allAvailableData)
            }
          }
        ]
      },
      yAxis: {
        tickInterval: 1,
        labels: {
          enabled: false
        }
      },
      xAxis: {
        scrollbar: {
          enabled: false
        }
      },
      navigator: {
        enabled: false
      },
      title: {
        text: `${constants.connectionHistory}- ${typeAggregate}`
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
            crop: false,
            format: '{point.y}',
            style: {
              color: 'black'
            }
          }
        }
      },
      scrollbar: { enabled: false },
      credits: {
        enabled: false
      },
      series: [{
        name: 'Number of connections ',
        type: 'column',
        data: dataChart
      }]
    });
  }


  customButtonClick(isInAggregatedDb: boolean, year: string, title: string): void {
    if (this.chart) {
      if (isInAggregatedDb) {
        this.getDataFromAggregatedHistoryTable(year).subscribe((newData: AggregatedData[]) => {
          this.initializeChart(newData, title);
        });
      } else {
        this.getDataFromConnectionHistoryTable(year).subscribe((newData: AggregatedData[]) => {
          this.initializeChart(newData, title);
        });
      }
    } else {
      this.uiService.openSnackBar('No charts to generate', undefined, undefined);
    }
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.customerIdInput) {
      this.customerId = changes.customerIdInput.currentValue;
    }
    if (changes.endDate) {
      this.endDate = changes.endDate.currentValue;
    }
    if (changes.startDate) {
      this.startDate = changes.startDate.currentValue;
    }
    if (this.startDate && this.endDate) {
      this.isLoading = false;
    }
  }

  getConnectionHistoryGraph(): void {
    this.isLoading = true;
    this.getDataFromAggregatedHistoryTable('all').subscribe({
      next: (connectionHistory: AggregatedData[]) => {
        this.initializeChart(connectionHistory, constants.allAvailableData);
        this.customButtonClick(false, constants.days, constants.last14Days);
        this.isLoading = false;
      },
      error: (err: any) => {
        this.uiService.openSnackBar('Error fetching connection history', undefined, undefined);
        console.error('Error fetching connection history:', err);
        this.isLoading = false;
      }
    });
  }

  getConnectionHistory(): void {
    this.isLoading = true;
    this.licencesServices.getCustomerConnectionHistory(this.customerId, this.endDate.toLocaleDateString(), this.startDate.toLocaleDateString()).subscribe({
      next: (conHis: ConnectionHistory[]) => {
        // Loop througth date to set nb connection history for bar graph
        this.nbConnectionKo = conHis.reduce((accumulator: number, a: ConnectionHistory) => accumulator + (a.error ? 1 : 0), 0);
        this.nbConnectionOk = conHis.length - this.nbConnectionKo;
        this.connectionHistoryList = new MatTableDataSource(conHis);
        this.connectionHistoryList.paginator = this.paginator;
      }
    });
  }

  getDataFromAggregatedHistoryTable(year: string): Observable<any[]> {
    return this.licencesServices.getConnectionHistoryAggregated(this.customerId, year).pipe(
      map((conHis: AggregatedData[]) => conHis.map((element: AggregatedData) => [element.aggregatedDate, element.aggregatedCount]))
    );
  }

  getDataFromConnectionHistoryTable(year: string): Observable<any[]> {
    return this.licencesServices.getConnectionHistory(this.customerId, year).pipe(
      map((conHis: AggregatedData[]) =>
        conHis.map((element: AggregatedData) => [element.aggregatedDate, element.aggregatedCount]))
    );
  }

}

