<vws-loader [loading]="isLoading" mode="indeterminate"></vws-loader>
<div class="column-100">

<div class="row-100">
  <div class="create-button">
    <button *ngIf="isAdmin" color="primary" mat-raised-button (click)="createUser()"><mat-icon>person_add</mat-icon>Create User</button>
  </div>

  <mat-form-field class="search-density search-margin" appearance="fill">
    <mat-label>Search</mat-label>
    <input matInput [(ngModel)]="search" (keyup)="applyFilter($event)" placeholder="Search" #input>
  </mat-form-field>
</div>

<div *ngIf="usersList" class="width-100">
  <table mat-table [dataSource]="usersList" matSort class="table-margin mat-elevation-z4 width-100">
      <!-- Name Column -->
      <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
          <td mat-cell *matCellDef="let user">
                {{user.name}}
          </td>
      </ng-container>

      <!-- Surname -->
      <ng-container matColumnDef="surname" >
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Surname</th>
          <td mat-cell *matCellDef="let user">
            {{user.surname}}
          </td>
      </ng-container>
      
      <!-- Username Column -->
      <ng-container matColumnDef="username" >
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Username</th>
          <td mat-cell *matCellDef="let user">
            {{user.username}}
          </td>
      </ng-container>
      
      <!-- Status Column -->
      <ng-container matColumnDef="roles" >
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Role </th>
          <td mat-cell *matCellDef="let user"> {{arrayOrderRole[user.roles-1]}} </td>
      </ng-container>

      <!-- Category Column -->
      <ng-container matColumnDef="email" >
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
        <td mat-cell *matCellDef="let user"> {{user.email}} </td>
    </ng-container>

      <!-- Password Link Column -->
      <ng-container matColumnDef="passwordLink" >
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Password Link </th>
        <td mat-cell *matCellDef="let user"> <ng-container *ngIf="user.passwordLink">{{ user.passwordLink.link }}</ng-container></td>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="Action" >
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let user"><ul (click)="$event.stopPropagation()">
            <li>
                <button mat-icon-button (click)="editUserDialog(user)" matTooltip="Edit">
                    <mat-icon>edit</mat-icon>
                </button>
            </li>
            <li>
              <button mat-icon-button (click)="deleteUser(user)" matTooltip="Delete" *ngIf="userConnected.email !== user.email">
                  <mat-icon>delete</mat-icon>
              </button>
            </li>
            <li>
              <button mat-icon-button *ngIf="user.passwordLink && !user.passwordLink.email_send" (click)="sendEmail(user)" matTooltip="Send email">
                  <mat-icon>email</mat-icon>
              </button>
            </li>
          </ul>
        </td>
      </ng-container>

      <ng-container matColumnDef="Visibility" >
        <th mat-header-cell *matHeaderCellDef> Visibility </th>
        <td mat-cell *matCellDef="let user"> <mat-slide-toggle color="primary" (click)="updateUser(user)" [checked]="user.shareReports">
      </mat-slide-toggle></td>
    </ng-container>

    <ng-container  *ngIf="isAdminSv()">
      <tr mat-header-row *matHeaderRowDef="displayedColumnsAdminSv"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsAdminSv;" class="mat-row-artifacts"
          [ngStyle]="{'cursor': 'pointer', 'font-weight': row.alreadyRead? 'normal' : 'bold'}">
      </tr>
    </ng-container>

      <ng-container  *ngIf="!isAdminSv() && isAdmin()">
        <tr mat-header-row *matHeaderRowDef="displayedColumnsAdmin"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsAdmin;" class="mat-row-artifacts"
            [ngStyle]="{'cursor': 'pointer', 'font-weight': row.alreadyRead? 'normal' : 'bold'}">
        </tr>
      </ng-container>
  </table>

</div>
</div>